.about_founders_container_1 {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
}

.afc1_title {
    font-size: 48px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.afc1_text {
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}

.about_founders_container_2 {
    padding-top: 20vh;
    /* padding-bottom: 20vh; */
    background-color: #fff;
    width: 100%;
}

.about_founders_content_container_2 {
    width: 100%;
    margin: 0 auto;
    grid-row: 2;
    display: grid;
}

.founder_container {
    margin-bottom: 10rem;
}

.founder_img_container {
    width: 80%;
    height: auto;
    margin: 0 auto;
}

.founder_img {
    width: 100%;
    height: 58vh;
    object-fit: cover;
}

.founder_details {
    display: grid;
    grid-template-rows: 3;
    grid-gap: 1rem;
    width: 100%;
    padding: 2rem;
}

.founder_name {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: left;
}

.founder_line {
    width: 75px;
    height: 1px;
    background-color: rgb(132, 132, 132);
    margin: 0;
    padding: 0;
}

.founder_social {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: -1%;
    text-align: left;
}

.founder_text {
    font-size: 14px;
    text-align: justify;
    margin: 0;
    padding: 0;
}
