.blog_container_1 {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
}

.bc1_title {
    font-size: 48px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.bc1_text {
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}

.blog_container_2 {
    width: 100%;
    padding-top: 20vh;
    padding-bottom: 5vh;
}

.bc2_content_container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: 2rem;
}

.blog_card_container {
    width: 122.5%;
    padding-left: 35%;
}

.blog_card {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    margin-bottom: 5rem;
}

.blog_card_img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.blog_card_title {
    font-size: 26px;
    margin: 0;
    margin-top: 1.5rem;
    text-align: left;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.blog_card_title:hover {
    transition: 0.3s ease-in-out;
    color: #ff5325;
}

.blog_card_details {
    width: 60%;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    text-align: left;
    margin-top: 1rem;
    color: rgb(132, 132, 132);
}

.blog_icon {
    margin-right: 5px;
}

.blog_card_description {
    color: rgb(96, 96, 96);
    text-align: left;
    margin-top: 1rem;
    line-height: 1.5;
}

.bc2_side_container {
    width: 37%;
    margin-left: 15rem;
}

.bc2_search_bar_container {
    width: 100%;
    height: 125px;
    padding-top: 11.5%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bc2_search_bar {
    width: 80%;
    max-width: 500px;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    padding: 0 1rem;
    font-size: 16px;
    color: #000;
    outline: none;
    margin: auto;
}

.bc2_search_input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #000;   
}

.bc2_search_icon {
    width: 20px;
    height: 20px;
    margin-left: 1rem;
    cursor: pointer;
    position: relative;
    margin-top: -13%;
    float: right;
    color: rgb(132, 132, 132);
}

.bc2_categories_container {
    width: 100%;
    height: 250px;
    padding-top: 10%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 3rem;
}

.bc2_categories_title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
}

.bc2_categories_list_container {
    width: 100%;
    height: 100%;
    padding-top: 5%;
    padding-left: 1.5rem;
}

.bc2_categories_list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.bc2_categories_list_item {
    font-size: 16px;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-align: left;
    color: rgb(96, 96, 96);
}

.bc2_categories_list_item:hover {
    transition: 0.3s ease-in-out;
    color: #ff5325;
}

.bc2_categories_list_item_icon {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    color: #ff5325;
}

.bc2_recent_posts_container {
    width: 100%;
    height: 450px;
    padding-top: 10%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 3rem;
}

.bc2_recent_posts_title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
}

.bc2_recent_posts_list_container {
    width: 100%;
    height: 100%;
    padding-top: 5%;
    padding-left: 1.5rem;
}

.bc2_recent_posts_list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.bc2_recent_posts_list_item {
    font-size: 16px;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-align: left;
    color: rgb(96, 96, 96);
    grid-template-columns: repeat(2, 1fr);
    display: grid;
}

.bc2_recent_posts_list_item_img_container {
    width: 50%;
}

.bc2_recent_posts_list_item_img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.bc2_recent_posts_list_item_text_container {
    width: 120%;
    margin-left: -4rem;
}

.bc2_recent_posts_list_item_text {
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    font-size: 0.9rem;
    font-weight: 700;
}

.bc2_recent_posts_list_item:hover {
    transition: 0.3s ease-in-out;
    color: #ff5325;
}
