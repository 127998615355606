/* Navbar */

@media only screen and (min-width: 568px) {
  .navbar_logo_text_1 {
    font-size: 3.5vw;
  }

  .navbar_logo_text_2 {
    font-size: 2.5vw;
  }
}

@media only screen and (min-width: 768px) {

  .navbar_logo_text,
  .demo_button_container {
    display: block;
  }

  .navbar_logo_text_1 {
    font-size: 1.6vw;
  }

  .navbar_logo_text_2 {
    font-size: 0.88vw;
  }

  .nav_item {
    display: flex;
  }

  .hamburger_menu {
    display: none;
  }

  .nav_item_a {
    padding: 30px 2px 30px 2px;
    font-size: 1vw;
  }

  .nav_item_a_scrolled {
    font-size: 1vw;
  }
}

@media only screen and (min-width: 992px) {
  .nav_item_a {
    padding: 30px 10px 30px 10px;
    font-size: 1vw;
  }

  .nav_item_a_scrolled {
    font-size: 0.65vw;
  }
}

@media only screen and (min-width: 1200px) {
  .nav_item_a {
    padding: 30px 20px 30px 20px;
    font-size: 0.85vw;
  }

  .nav_item_a_scrolled {
    font-size: 0.65vw;
  }
}

/* Home */

@media only screen and (min-width: 488px) {
  .home_container_1 {
    height: 85vh;
  }

  .welcome_desc {
    font-size: 3vw;
  }

  .welcome_text {
    font-size: 2vw;
  }

  .about_container_text {
    font-size: 3vw;
  }

  .about_container_desc {
    font-size: 2.2vw;
  }

  .home_subscribe_icon_container {
    margin-top: -20%;
  }
}

@media only screen and (min-width: 768px) {
  .home_container_1 {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    padding-top: 15vh;
    padding-bottom: 10vh;
    height: 85vh;
  }

  .welcome_desc {
    font-size: 4vw;
    margin-left: 12vw;
    margin-top: 2rem;
    width: 90%;
  }

  .welcome_text {
    font-size: 2vw;
    margin-left: 12vw;
    margin-top: 2rem;
    width: 90%;
  }

  .learn_button {
    padding: 0.75vw 1.5vw;
    font-size: 0.85vw;
    margin-top: 1rem;
  }

  .home_img {
    display: inline;
    margin-top: 2vw;
    margin-left: 12vw;
    animation: about_img_slide 6s ease-in-out infinite;
    width: 28vw;
    height: 28vw;
  }

  .home_carousel_1 {
    display: block;
  }

  .home_carousel_2 {
    display: none;
  }

  .stats_container {
    width: 85%;
    margin: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 2;
  }

  .home_container_5 {
    /* padding-bottom: 20vh; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
    width: 100%;
    padding-top: 20vh;
  }

  .img_container_2 {
    display: block;
  }

  .about_container_text {
    font-size: 3vw;
    width: 150%;
  }

  .about_container_desc {
    font-size: 2vw;
    width: 150%;
  }

  .about_img {
    display: block;
    margin-left: 2rem;
  }

  .home_container_8 {
    width: 50%;
  }

  .home_subscribe_title {
    font-size: 4vw;
  }

  .home_subscribe_text {
    font-size: 2vw;
  }

  .home_subscribe_icon_container {
    margin-top: -20%;
  }

  .home_subscribe_icon {
    width: 15%;
    height: 15%;
  }

  .home_subscribe_button {
    width: 2.5rem;
    height: 2.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: -3.2rem;
  }

  .home_subscribe_input {
    padding-top: 1rem;
    font-size: 1.6vw;
  }

  .send_icon {
    width: 90%;
    height: 90%;
  }
}

@media only screen and (min-width: 992px) {
  .home_container_1 {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    padding-top: 25vh;
    padding-bottom: 20vh;
  }

  .welcome_desc {
    font-size: 2.8vw;
    margin-left: 12vw;
    margin-top: 2rem;
    width: 80%;
  }

  .welcome_text {
    font-size: 0.96vw;
    margin-left: 12vw;
    margin-top: 2rem;
    width: 80%;
  }

  .learn_button {
    padding: 0.75vw 1.5vw;
    font-size: 0.85vw;
    margin-top: 1rem;
  }

  .home_img {
    margin-top: -4vw;
    margin-left: -4vw;
  }

  .stats_container {
    width: 85%;
    margin: auto;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1;
  }

  .stat_firstLine {
    font-size: 1.2vw;
  }

  .stat_secondLine {
    font-size: 0.8vw;
  }

  .about_container_text {
    width: 130%;
  }

  .about_container_desc {
    width: 90%;
    font-size: 1.5vw;
  }

  .about_img {
    margin-left: 0rem;
  }

  .hc7_row_1 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1;
    grid-gap: 1rem;
  }

  .hc7_row_2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
  }

  .hc7_img_1 {
    height: 10vh;
    width: 15.625vw;
    margin-top: 4.5vh;
  }

  .hc7_img_2 {
    height: 20vh;
    width: 15.625vw;
  }

  .hc7_img_3 {
    height: 17.5vh;
    width: 13.02vw;
    margin-top: 3vh;
    margin-left: -1.5625vw;
  }

  .hc7_img_4 {
    height: 15vh;
    width: 13.02vw;
    margin-top: 4vh;
    margin-left: -1.5625vw;
  }

  .hc7_img_5 {
    height: 25vh;
    width: 13.02vw;
    margin-top: -4.5vh;
  }

  .hc7_img_6 {
    height: 20vh;
    width: 13.02vw;
    margin-top: 1.5vh;
  }

  .hc7_img_7 {
    height: 10vh;
    width: 18.23vw;
    margin-top: 7vh;
    margin-left: 0.8vw;
  }

  .hc7_img_8 {
    height: 15vh;
    width: 15.625vw;
    margin-left: -1vw;
    margin-top: 5vh;
  }

  .hc7_img_9 {
    height: 10vh;
    width: 15.625vw;
    margin-top: 8.2vh;
  }

  .home_container_8 {
    width: 35%;
  }

  .home_subscribe_title {
    font-size: 2.5vw;
  }

  .home_subscribe_text {
    font-size: 1.5vw;
  }

  .home_subscribe_input {
    font-size: 1.2vw;
  }

  .send_icon {
    width: 100%;
    height: 100%;
  }

  .home_news_card_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
    margin-top: 5rem;
  }

  .carousel1 {
    display: block;
    height: 50rem;
  }

  .carousel2 {
    display: none;
  }

  .home_news_card_title {
    font-size: 0.8rem;
  }

  .home_news_card_desc {
    font-size: 0.6rem;
  }
}

@media only screen and (min-width: 1200px) {
  .home_container_2 {
    background-color: rgb(250, 250, 250);
    padding-bottom: 20vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
    width: 100%;
  }

  .hc2_responsive_title {
    display: none;
  }

  .tab_container {
    max-width: 60%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 7%;
    margin-left: 35vh;
  }

  .tab_1,
  .tab_3 {
    margin-top: 0vh;
  }

  .tab_2,
  .tab_4 {
    margin-top: 5vh;
  }

  .platform_card,
  .platform_card_selected {
    width: 250px;
    height: 240px;
  }

  .platform_card_selected {
    margin-top: -0.625rem;
  }

  .platform_card_title {
    margin-top: 0rem;
    font-size: 1rem;
    font-weight: 700;
  }

  .platform_card_desc {
    margin-top: 0.938rem;
    font-size: 0.8rem;
    font-weight: 400;
    color: rgb(96, 96, 96);
  }

  .platform_icon {
    height: 3rem;
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .desc_container {
    display: block;
    padding-top: 20vh;
    max-width: 100%;
    margin: 0;
    margin-left: 5rem;
    margin-top: -1rem;
  }

  .home_container_4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
  }

  .hc4_responsive_title {
    display: none;
  }

  .service_container {
    display: block;
  }

  .service_text {
    font-size: 2.5vw;
  }

  .service_desc {
    font-size: 1.2vw;
  }

  .service_card_container {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  .service_card,
  .service_card_selected {
    width: 260px;
    height: 230px;
  }

  .service_card_selected {
    margin-top: -0.625rem;
  }

  .service_icon {
    height: 3rem;
    width: 3rem;
  }

  .service_card_selected .service_icon {
    height: 3rem;
    width: 3rem;
  }

  .about_container_text {
    width: 100%;
    font-size: 1.76vw;
  }

  .about_container_desc {
    width: 90%;
    font-size: 1.1vw;
  }

  .about_img {
    margin-left: -2rem;
  }

  .home_subscribe_title {
    font-size: 1.667vw;
  }

  .home_subscribe_text {
    font-size: 0.8vw;
  }

  .home_subscribe_input {
    font-size: 0.8vw;
  }

  .home_news_card {
    width: 100%;
    height: 625px;
    margin-top: 2rem;
  }

  .home_news_card_title {
    font-size: 1.1rem;
  }

  .home_news_card_desc {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 1400px) {
  .about_container_desc {
    font-size: 0.8vw;
  }

  .platform_card,
  .platform_card_selected {
    width: 300px;
    height: 290px;
  }

  .platform_card_title {
    margin-top: 0.625rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .platform_card_desc {
    margin-top: 0.938rem;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(96, 96, 96);
  }

  .platform_icon {
    height: 5rem;
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .service_card,
  .service_card_selected {
    width: 312px;
    height: 290px;
  }

  .service_icon {
    height: 5rem;
    width: 5rem;
  }

  .service_card_selected .service_icon {
    height: 5rem;
    width: 5rem;
  }

  .service_text {
    font-size: 1.76vw;
  }

  .service_desc {
    font-size: 0.8vw;
  }
}

/* About */

@media only screen and (min-width: 1200px) {
  .about_container_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
  }

  .about_about_img {
    margin-left: -10rem;
    height: 50%;
  }

  .about_text {
    width: 60%;
  }

  .about_desc {
    width: 90%;
  }

  .about_list {
    width: 60%;
  }

  .about_list_2 {
    width: 150%;
  }
}

@media only screen and (min-width: 1400px) {
  .about_about_img {
    width: 100%;
    height: auto;
  }

  .about_desc {
    width: 70%;
  }
}

/* About Founders */

@media only screen and (min-width: 992px) {
  .founder_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  .about_founders_content_container_2 {
    width: 60%;
    margin: 0 auto;
    grid-row: 2;
    display: grid;
  }

  .founder_details {
    width: 130%;
    margin-left: -25%;
    padding: 1rem;
  }

  .founder_img_container {
    width: 70%;
    margin-left: -1%;
  }
}

/* Careers */

@media only screen and (min-width: 580px) {
  .careers_card_container {
    width: 60%;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) {
  .careers_card_container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    display: grid;
    grid-row: 2;
    width: 90%;
    margin: auto;
  }

  .careers_modal_container {
    margin-top: 5%;
    margin-left: 30%;
    width: 70%;
    height: 80%;
    margin: auto;
  }

  .careers_card_title {
    font-size: 1.2rem;
  }

  .careers_card_button {
    width: 60%;
  }

}

@media only screen and (min-width: 992px) {
  .careers_card_button {
    width: 40%;
  }
}

@media only screen and (min-width: 1400px) {
  .careers_card_container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    display: grid;
    grid-row: 2;
    width: 65%;
    margin: auto;
  }

  .careers_card_logo {
    width: 60px;
    height: 60px;
    margin-top: -30px;
  }

  .careers_card_title {
    font-size: 1.2rem;
  }

  .careers_card_company_container {
    font-size: 0.9rem;
  }

  .careers_card_button {
    width: 60%;
  }
}

@media only screen and (min-width: 1700px) {
  .careers_card_container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    display: grid;
    grid-row: 2;
    width: 65%;
    margin: auto;
  }

  .careers_card_logo {
    width: 90px;
    height: 90px;
    margin-top: -30px;
  }

  .careers_card_title {
    font-size: 1.5rem;
  }

  .careers_card_company_container {
    font-size: 1rem;
  }

  .careers_card_button {
    width: 40%;
  }
}

/* Contact */

@media only screen and (min-width: 1200px) {
  .contact_card_container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10rem;
    display: grid;
    width: 50%;
    margin: auto;
  }

  .cc3_content_container {
    width: 80%;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    display: grid;
    margin-top: 5rem;
  }

  .contact_details_2_container .contact_details_text_container {
    margin-left: -2.5%;
  }

  .contact_form_container {
    margin-left: 10%;
  }

  .contact_details_container {
    margin-top: 0rem;
  }

  .contact_details_icon_container {
    width: 100%;
  }

  .contact_details_text_container {
    width: 100%;
    margin-left: -30%;
  }

  .contact_details_2_container .contact_details_text_container {
    margin-left: -30%;
  }

  .contact_map_1 {
    display: block;
  }

  .contact_map_2 {
    display: none;
  }
}

/* News */

@media only screen and (min-width: 1200px) {
  .news_card_container {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    width: 80%;
    margin: auto;
    margin-bottom: 5rem;
  }

  .news_card_container {
    width: 80%;
    margin: auto;
    margin-bottom: 5rem;
  }

  .news_card_img {
    height: 200px;
  }

  .news_card_title {
    font-size: 1rem;
  }

  .news_card_desc {
    font-size: 0.8rem;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 1600px) {
  .news_card_img {
    height: 300px;
  }

  .news_card_title {
    font-size: 1.3rem;
  }

  .news_card_desc {
    font-size: 1rem;
    margin-bottom: 0;
  }
}

/* Impact Stories */

@media only screen and (min-width: 658px) {
  .is_responsive_posts_list_item_img {
    width: 30vw;
    height: 150px;
  }

  .is_responsive_posts_list_item_text_container {
    width: 100%;
    margin-left: -10vw;
  }

  .is_responsive_posts_list_item_text,
  .is_responsive_posts_list_item_text_selected {
    font-size: 3vw;
    padding-right: 1rem;
  }
}

@media only screen and (min-width: 922px) {

  .is_responsive_posts_list_item_text,
  .is_responsive_posts_list_item_text_selected {
    font-size: 2vw;
  }
}

@media only screen and (min-width: 1200px) {
  .isc2_content_container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: 2rem;
  }

  .impact_stories_card_container {
    width: 122.5%;
    padding-left: 35%;
  }

  .impact_stories_card_zoom_icon {
    display: block;
  }

  .isc2_recent_posts_container {
    display: block;
  }

  .is_carousel {
    display: none;
  }
}

@media only screen and (min-width: 1350px) {
  .isc2_side_container {
    width: 55%;
    margin-left: 15rem;
  }
}

@media only screen and (min-width: 1500px) {
  .isc2_side_container {
    width: 45%;
    margin-left: 15rem;
  }
}

@media only screen and (min-width: 1800px) {
  .isc2_side_container {
    width: 37%;
    margin-left: 15rem;
  }
}

/* Footer */

@media only screen and (min-width: 1200px) {
  .footer {
    padding-bottom: 10vh;
    width: 100%;
    padding-top: 10vh;
    background-color: rgb(250, 250, 250);
    color: #fff;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
    display: grid;
    padding-left: 10%;
    padding-right: 0%;
  }

  .footer_company_responsive_text {
    display: none;
  }

  .footer_contact_container_2 {
    display: block;
  }

  .footer_navigation_container {
    display: block;
  }

  .footer_company_title {
    text-align: left;
  }

  .footer_company_social_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    justify-content: left;
  }

  .footer_contact_container_2 {
    margin-left: 5%;
  }

  .footer_line {
    margin-left: 0;
  }

}

@media only screen and (min-width: 1400px) {
  .footer {
    padding-bottom: 10vh;
    width: 100%;
    padding-top: 10vh;
    background-color: rgb(250, 250, 250);
    color: #fff;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
    display: grid;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer_contact_container_2 {
    margin-left: 0%;
  }

  .footer_news_container_2 {
    display: block;
  }
}

@media only screen and (min-width: 1600px) {
  .footer {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media only screen and (min-width: 1800px) {
  .footer {
    padding-left: 15%;
    padding-right: 15%;
  }
}