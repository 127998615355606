.careers_container_1 {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
}

.csc1_title {
    font-size: 48px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.csc1_text {
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}

.careers_container_2 {
    padding-top: 10vh;
    padding-bottom: 20vh;
}

.careers_card_container {
    width: 90%;
    margin: auto;
}

.careers_card {
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem 1rem 1rem 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
}

.careers_card_logo {
    width: 60px;
    height: 60px;
    margin-top: -30px;
    float: right;
}

.careers_card_company_container {
    text-align: left;
    margin-top: 1rem;
    width: 90%;
}

.careers_card_title {
    font-size: 1.5rem;
    margin: 0;
    margin-top: 20px;
    padding: 0;
    font-weight: 700;
    text-align: left;
}

.careers_card_content_container {
    width: 90%;
    margin: auto;
    margin-top: 1.5rem;
    text-align: left;
}

.careers_card_info_list li {
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
}

.careers_card_button {
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.7rem 1rem;
    font-size: 14px;
    margin-top: 2rem;
    cursor: pointer;
    width: 40%;
    position: relative;
    z-index: 2;
}

.careers_card_button::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to left, #00d581, #00d581);
    z-index: 1;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    border-radius: 5px;
}

.careers_card_button:hover::before {
    opacity: 1;
    z-index: -1;
}

.careers_modal {
    position: fixed;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    justify-content: center;
    align-items: center;
}

.careers_modal_container {
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem 1rem 3rem 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
    width: 80%;
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
}

.careers_modal_title {
    font-size: 1.5rem;
    margin: 0;
    margin-top: 20px;
    margin-left: 1rem;
    padding: 0;
    font-weight: 700;
    text-align: left;
}

.careers_modal_content_container {
    width: 90%;
    margin: auto;
    margin-top: 1.5rem;
    text-align: left;
}

.careers_modal_p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-top: 1.5rem;
    line-height: 1.5;
}

.careers_modal_p ul {
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: 1rem;
}

.careers_modal_p p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: 0.5rem;
}
