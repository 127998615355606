.footer {
    padding-bottom: 4vh;
    width: 100%;
    padding-top: 10vh;
    background-color: rgb(250, 250, 250);
    color: #fff;
    padding-left: 15%;
    padding-right: 15%;
}

.footer_company_container {
    width: 100%;
    margin: auto;
    height: 100%;
}

.footer_company_title {
    font-size: 1.5rem;
    color: #000;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 2%;
}

.footer_company_text {
    font-size: 1rem;
    color: rgb(96, 96, 96);
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 1.5rem;
    width: 100%;
}

.footer_company_responsive_text {
    font-size: 0.9rem;
    color: rgb(96, 96, 96);
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: -1rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.footer_company_responsive_text p {
    margin-top: 1rem;
}

.footer_company_social_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    justify-content: center;
    gap: 1rem;
}

.footer_social_icon {
    width: 1.2rem;
    height: 1.2rem;
    /* margin-right: 1.5rem; */
    color: #0094f8;
    cursor: pointer;
}

.footer_contact_container_2 {
    width: 100%;
    margin: auto;
    margin-top: 2%;
    display: none;
}

.footer_contact_title_2 {
    font-size: 1.5em;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 0;
}

.footer_contact_text_2 {
    font-size: 1rem;
    color: rgb(96, 96, 96);
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 1.5rem;
    width: 100%;
}

.footer_contact_text_2_inner p {
    margin-top: 1rem;
}

.footer_contact_text_2>p {
    margin-top: 1.8rem;
}

.footer_contact_icon {
    margin-right: 1.5rem;
    color: #0094f8;
}

.footer_navigation_container {
    width: 100%;
    margin: auto;
    margin-top: 2%;
    margin-left: 5%;
    display: none;
}

.footer_navigation_title {
    font-size: 1.5em;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 0;
}

.footer_navigation_text {
    font-size: 1rem;
    color: rgb(96, 96, 96);
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 1.5rem;
    width: 100%;
}

.footer_navigation_text>p {
    margin-top: 1.8rem;
}

.footer_navigation_text>p:hover {
    color: #ff5325;
    cursor: pointer;
}

.footer_navigation_link {
    text-decoration: none;
    color: rgb(96, 96, 96);
}

.footer_navigation_link:hover {
    color: #ff5325;
    cursor: pointer;
}

.footer_news_container_2 {
    width: 130%;
    margin: auto;
    margin-left: -10%;
    margin-top: 2%;
    display: none;
}

.footer_news_title_2 {
    font-size: 1.5em;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 0;
}

.footer_news_card_container_2 {
    margin-top: -5%;
    margin-left: -5%;
}

.footer_news_card_2 {
    width: 100%;
    height: 100%;
    padding-bottom: 2%;
}

.footer_news_card_2_img_container {
    width: 30%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.footer_news_card_img_2 {
    width: 100px;
    height: 60px;
    margin: 0;
    padding: 0;
    float: left;
}

.footer_news_card_2_text {
    width: 70%;
    height: 100%;
    margin: 0;
    padding: 0;
    float: right;
    text-align: left;
    padding-left: 5%;
    font-size: 1rem;
}

.footer_news_card_2>p {
    margin-top: 1rem;
    color: rgb(96, 96, 96);
}

.footer_news_card_2:hover {
    color: #ff5325;
    cursor: pointer;
}

.footer_line {
    width: 40px;
    background-color: #0094f8;
    height: 5px;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    border: none;
    margin-left: auto;
    margin-right: auto;
}
