.home_container_1 {
    padding-top: 5vh;
    padding-bottom: 20vh;
    height: 85vh;
}

.welcome_container {
    width: 100%;
}

.welcome_desc {
    font-size: 6vw;
    color: #004c90;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 12vw;
    /* margin-top: -10vh; */
    width: 80%;
    transition: all 1.5s ease-in;
}

.welcome_text {
    font-size: 4vw;
    color: #004c90;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 12vw;
    margin-top: 2rem;
    transition: all 1.5s ease-in;
    width: 80%;
}

.learn_more {
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 12vw;
    margin-top: 2rem;
}

.learn_button {
    background-image: linear-gradient(to right, #0094f8, #00d581);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 2vw 4vw;
    font-size: 2.5vw;
    margin-top: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in;
    position: relative;
    z-index: 2;
}

.learn_button::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to left, #00d581, #00d581);
    z-index: 1;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    border-radius: 5px;
}

.learn_button:hover::before {
    opacity: 1;
    z-index: -1;
}

.learn_button_link {
    text-decoration: none;
    color: #fff;
}

.home_img {
    /* display: none; */
    width: 15rem;
    height: 15rem;
    margin-bottom: 6vh;
    left: 0;
    z-index: -1;
}

@keyframes about_img_slide {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(1rem);
    }

    100% {
        transform: translateY(0);
    }
}

.home_carousel_1 {
    display: none;
}

.home_container_2 {
    background-color: rgb(250, 250, 250);
    padding-bottom: 20vh;
    padding-top: 10vh;
    width: 100%;
}

.hc2_responsive_title {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    color: #004c90;
}

.tab_container {
    width: 100%;
    margin-top: 7%;
}

.tab_1,
.tab_2,
.tab_3,
.tab_4 {
    margin-top: 5vh;
}

.platform_card {
    width: 300px;
    height: 290px;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;
    padding-top: 2.625rem;
    padding-right: 1.875rem;
    padding-bottom: 1.875rem;
    padding-left: 1.875rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.platform_card_selected {
    width: 300px;
    height: 290px;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;
    padding-top: 2.625rem;
    padding-right: 1.875rem;
    padding-bottom: 1.875rem;
    padding-left: 1.875rem;
    cursor: pointer;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
    margin-left: auto;
    margin-right: auto;
}

.platform_card::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    z-index: 1;
    transition: all 0.3s ease-in;
    opacity: 0;
}

.platform_card:hover {
    color: #fff;
    margin-top: -0.625rem;
    transition: all 0.3s ease-in;
}

.platform_card_selected .platform_icon {
    color: #fff;
}

.platform_card_selected .platform_card_title {
    color: #fff;
}

.platform_card:hover .platform_card_desc,
.platform_card_selected .platform_card_desc {
    color: #fff;
}

.platform_card:hover::before {
    opacity: 1;
    z-index: -1;
}

.platform_card_title {
    margin-top: 0.625rem;
    font-size: 1.25rem;
    font-weight: 700;
}

.platform_card_desc {
    margin-top: 0.938rem;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(96, 96, 96);
}

.platform_icon {
    height: 5rem;
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.desc_container {
    display: none;
    padding-top: 20vh;
    max-width: 100%;
    margin: 0;
    margin-left: 5rem;
    margin-top: -1rem;
}

.desc_title,
.service_title,
.about_container_title {
    font-weight: 700;
    font-size: 1.2rem;
    color: #004c90;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 10%;
}

.desc_text {
    font-size: 2.2rem;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
    width: 60%;
    transition: all 0.2s ease-in;
}

.desc_desc {
    margin-top: 2rem;
    width: 60%;
    text-align: left;
    font-size: 1rem;
    line-height: 2;
    color: rgb(96, 96, 96);
    transition: all 0.2s ease-in;
}

.learn_more_2 {
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
    width: 80vh;
}

.home_container_3 {
    width: 100%;
    background-image: linear-gradient(to right, #0094f8, #00d581);
    padding-top: 8vh;
    padding-bottom: 8vh;
}

.stats_container {
    width: 85%;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 3;
    grid-gap: 2rem;
    display: grid;
}

.stat_1,
.stat_2,
.stat_3,
.stat_4,
.stat_5,
.stat_6 {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    background-image: url(../../assets/stats_bg/count-shape.png);
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 55px;
    padding-bottom: 70px;
    transition: all 0.2s ease-in;
}

/* .stat_1:hover,
.stat_2:hover,
.stat_3:hover,
.stat_4:hover,
.stat_5:hover,
.stat_6:hover {
    background-position-x: 100%;
    transition: all 0.2s ease-in;
} */

.stat_firstLine {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.stat_secondLine {
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.home_container_4 {
    width: 100%;
    padding-top: 20vh;
}

.hc4_responsive_title {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    color: #004c90;
}

.service_container {
    display: none;
    padding-left: 35vh;
    width: 85%;
    margin-top: 5rem;
}

.about_container {
    padding-left: 17vw;
    width: 90%;
    margin-top: -5%;
}

.service_text {
    font-size: 1.76vw;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
    width: 100%;
}

.service_desc {
    margin-top: 2rem;
    width: 100%;
    text-align: left;
    font-size: 0.8vw;
    line-height: 2;
}

.about_container_text {
    font-size: 5vw;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
    width: 100%;
}

.about_container_desc {
    margin-top: 2rem;
    width: 100%;
    text-align: left;
    font-size: 4vw;
    line-height: 2;
}

.learn_more_3,
.learn_more_4 {
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
    /* width: 80vh; */
}

.service_card {
    width: 312px;
    height: 290px;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
}

.service_card_selected {
    width: 312px;
    height: 290px;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 2;
    cursor: pointer;
    color: #fff;
    /* margin-top: -10px; */
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
}

.service_card::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    z-index: 1;
    transition: all 0.3s ease-in;
    opacity: 0;
}

.service_card:hover {
    color: #fff;
    margin-top: -10px;
    transition: all 0.3s ease-in;
}

.service_card:hover::before {
    opacity: 1;
    z-index: -1;
}

.service_card_title {
    font-size: 20px;
    margin-top: 20px;
}

.service_card_selected .service_card_title {
    font-size: 20px;
    margin-top: 20px;
    color: #fff;
}

.service_card_container {
    width: 100%;
}

.service_icon {
    height: 5rem;
    width: 5rem;
    margin-top: 3.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.service_card_selected .service_icon {
    height: 5rem;
    width: 5rem;
    margin-top: 3.5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    color: #fff;
}

.home_container_5 {
    /* padding-bottom: 20vh; */
    width: 100%;
    padding-top: 20vh;
}

.img_container_2 {
    width: 87%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: none;
}

.about_img {
    width: 36vw;
    height: 28vw;
    top: 0;
    left: 0;
    animation: about_img_slide 6s ease-in-out infinite;
    display: none;
}

/* .home_container_6 {
    padding-bottom: 20vh;
    width: 100%;
    padding-top: 20vh;
}

.process_container {
    width: 65%;
    margin: auto;
}

.process_title {
    font-size: 1.2rem;
    color: #0094f8;
    text-align: center;
    margin: 0;
    padding: 0;
}

.process_text {
    font-size: 2.2rem;
    color: #000;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
    width: 100%;
}

.process_card_container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1;
    grid-gap: 2rem;
    display: grid;
    width: 100%;
    margin-top: 2rem;
}

.process_1,
.process_2,
.process_3,
.process_4 {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #000;
    box-sizing: border-box;
    padding-top: 50px;
    padding-left: 55px;
    transition: all 0.2s ease-in;
}

.process_icon_container_container {
    width: 14rem;
    height: 14rem;
    text-align: center;
    color: #000;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 65px;
    transition: all 0.2s ease-in;
    border: 1px dotted rgb(96, 96, 96);
    border-radius: 50%;
}

.process_icon_container {
    width: 10rem;
    height: 10rem;
    text-align: center;
    color: #000;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 0.938rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: -17.5px;
    transition: all 0.2s ease-in;
    border-radius: 50%;
}

.process_icon_container:hover {
    transition: all 0.2s ease-in;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
}

.process_icon_container:hover .process_icon {
    transition: all 0.2s ease-in;
    color: #fff;
}

.process_icon {
    width: 50%;
    height: 50%;
    text-align: center;
    align-items: center;
    color: #0094f8;
    box-sizing: border-box;
    padding-top: 35px;
    margin: auto;
    transition: all 0.2s ease-in;
}

.process_card_title {
    font-size: 1.2rem;
    color: #000;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
} */

.home_container_7 {
    padding-bottom: 20vh;
    width: 100%;
    padding-top: 30vh;
    /* background-image: url(../../assets/hc7_bg/hc7-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    /* background-image: linear-gradient(to bottom right, rgba(0,148,248,1), rgba(0,213,129,1)); */
}

.partners_title {
    font-size: 1.2rem;
    font-weight: 700;
    color: #004c90;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: -8%;
    width: 100%;
}

.hc7_row_1 {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
}

.hc7_row_2 {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
}

.hc7_img_1,
.hc7_img_2,
.hc7_img_3,
.hc7_img_4,
.hc7_img_5,
.hc7_img_6,
.hc7_img_7,
.hc7_img_8,
.hc7_img_9 {
    margin-left: auto;
    margin-right: auto;
}

.hc7_img_1 {
    height: 100px;
    width: 300px;
    margin-left: -7.5px;
}

.hc7_img_2 {
    height: 200px;
    width: 300px;
    margin-top: 30px;
}

.hc7_img_3 {
    height: 175px;
    width: 250px;
    margin-top: 30px;
}

.hc7_img_4 {
    height: 150px;
    width: 250px;
    margin-top: 30px;
    margin-left: 10px;
}

.hc7_img_5 {
    height: 250px;
    width: 250px;
    margin-top: -35px;
    margin-left: 20px;
}

.hc7_img_6 {
    height: 200px;
    width: 250px;
    margin-top: -70px;
}

.hc7_img_7 {
    height: 100px;
    width: 350px;
    margin-top: 20px;
    margin-left: -45px;
}

.hc7_img_8 {
    height: 150px;
    width: 300px;
    margin-left: -10px;
    margin-top: 20px;
    margin-left: -20px;
}

.hc7_img_9 {
    height: 100px;
    width: 300px;
    margin-top: 20px;
    margin-left: -15px;
}

.home_container_8 {
    padding-bottom: 5vh;
    width: 70%;
    padding-top: 20vh;
    margin: auto;
    border-radius: 10px;
    margin-top: -8%;
    /* background-color: #fff; */
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    z-index: 2;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    animation: home_subscribe_container_slide 6s ease-in-out infinite;
}

@keyframes home_subscribe_container_slide {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(3rem);
    }

    100% {
        transform: translateY(0);
    }
}

.home_subscribe_icon_container {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
    transition: all 0.2s ease-in;
    margin-top: -30%;
}

.home_subscribe_icon {
    width: 10vh;
    height: 10vh;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    margin: auto;
    transition: all 0.2s ease-in;
}

.home_subscribe_title {
    font-size: 3.5vh;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}

.home_subscribe_text {
    font-size: 2.5vh;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0.75rem;
    margin-top: 1rem;
}

.home_subscribe_input_container {
    width: 100%;
    margin-top: 2rem;
}

.home_subscribe_input {
    width: 70%;
    height: 3.5rem;
    padding: 1rem;
    padding-top: 0.7rem;
    font-size: 3vw;
    color: rgb(132, 132, 132);
    text-align: left;
    margin: 0;
    margin-top: 1rem;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.home_subscribe_button {
    width: 4vh;
    height: 4vh;
    text-align: center;
    color: #000;
    box-sizing: border-box;
    padding-top: 7px;
    padding-bottom: 10px;
    margin-left: -2.5rem;
    margin-right: auto;
    transition: all 0.2s ease-in;
    border-radius: 50%;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    border: 1px solid #fff;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    position: relative;
}

.home_subscribe_button::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to bottom right, #00d581, #0094f8);
    z-index: 1;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    border-radius: 50%;
}

.home_subscribe_button:hover::before {
    opacity: 1;
    z-index: -1;
}

.send_icon {
    width: 1.5vh;
    height: 1.5vh;
    text-align: center;
    align-items: center;
    color: #fff;
    box-sizing: border-box;
    margin: auto;
    transition: all 0.2s ease-in;
}

.home_container_9 {
    padding-bottom: 5vh;
    width: 100%;
    padding-top: 20vh;
}

.home_news_container {
    width: 65%;
    margin: auto;
}

.home_news_title {
    font-size: 1.2rem;
    color: #004c90;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 0;
}

.home_news_text {
    font-size: 2.2rem;
    color: #000;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
    width: 100%;
}

.home_news_card_container {
    width: 100%;
    margin-top: 5rem;
}

.home_news_card {
    width: 100%;
    height: 570px;
    margin-top: 2rem;
}

.home_news_card_img {
    width: 100%;
    height: 25vh;
    margin: 0;
    padding: 0;
}

.home_news_icon {
    margin-right: 1rem;
}

.home_news_card_title {
    font-size: 1rem;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: 1rem;
    padding-right: 1rem;
    height: 75px;
}

.home_news_card_desc {
    font-size: 0.8rem;
    color: rgb(96, 96, 96);
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
    margin-left: 1rem;
    padding-right: 1rem;
    height: 75px;
}

.home_news_card_date {
    font-size: 1rem;
    color: rgb(132, 132, 132);
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: 1rem;
}

.read_more {
    font-size: 1rem;
    color: #064420;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: 1rem;
}

.carousel1 {
    display: none;
}

.carousel2 {
    display: block;
    height: 50rem;
}