.impact_stories_container_1 {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
}

.isc1_title {
    font-size: 48px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.isc1_text {
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}

.impact_stories_container_2 {
    width: 100%;
    padding-top: 20vh;
    padding-bottom: 5vh;
}

.isc2_content_container {
    width: 100%;
}

.impact_stories_card_container {
    width: 100%;
}

.impact_stories_card {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    margin-bottom: 5rem;
}

.impact_stories_card_img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.impact_stories_card_zoom_icon {
    position: relative;
    top: 0;
    right: 0;
    float: right;
    margin: 1rem;
    cursor: pointer;
    display: none;
}

.impact_stories_card_zoom_container {
    width: 75%;
    height: auto;
    max-height: 75%;
    position: fixed;
    margin-left: 12.5%;
    margin-top: 8%;
    background-color: #fff;
    z-index: 100;
    padding: 2rem;
}

.impact_stories_card_zoomed_img {
    width: 100%;
    height: auto;
    object-fit: cover;
    z-index: 100;
    background-color: #fff;
    padding: 1rem;
}

.impact_stories_card_zoomed_close_icon {
    position: relative;
    top: -2%;
    right: -1%;
    float: right;
    margin: 1rem;
    cursor: pointer;
    z-index: 100;
    height: 30px;
    width: 30px;
    color:rgb(132, 132, 132);
}

.impact_stories_card_title {
    font-size: 26px;
    margin: 0;
    margin-top: 1.5rem;
    text-align: left;
    font-weight: 700;
    transition: 0.3s ease-in-out;
}

.impact_stories_card_details {
    width: 60%;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    text-align: left;
    margin-top: 1rem;
    color: rgb(132, 132, 132);
}

.impact_stories_icon {
    margin-right: 5px;
}

.impact_stories_card_text {
    color: rgb(96, 96, 96);
    text-align: left;
    margin-top: 1rem;
    line-height: 1.5;
}

.impact_stories_card_text_p {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    text-align: justify;
}

.impact_stories_card_text_list {
    padding: 0;
    margin: 0;
    margin-left: 1.5rem;
}

.impact_stories_card_text_list li p {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    text-align: justify;
}

.impact_stories_card_text_table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    font-size: 0.8rem;
}

.impact_stories_card_text_table_row {
    border: 1px solid #e0e0e0;
}

.impact_stories_card_text_table_col_1 {
    width: 15%;
    padding: 0.5rem;
    text-align: left;
    border: 1px solid #e0e0e0;
}

.impact_stories_card_text_table_col_2 {
    width: 80%;
    padding: 0.5rem;
    text-align: left;
    padding-left: 2rem;
}

.impact_stories_card_text_table_2_col_1 {
    width: 40%;
    padding: 0.5rem;
    text-align: justify;
    border: 1px solid #e0e0e0;
}

.impact_stories_card_text_table_2_col_2 {
    width: 20%;
    padding: 0.5rem;
    text-align: center;
    /* padding-left: 2rem; */
    border: 1px solid #e0e0e0;
}
.impact_stories_card_text_table_2_col_3 {
    width: 40%;
    padding: 0.5rem;
    text-align: justify;
    /* padding-left: 2rem; */
}

.impact_stories_card_text_table_col_2 li {
    list-style-position: outside;
}

.isc2_side_container {
    width: 60%;
    margin-left: 10rem;
}

.isc2_recent_posts_container {
    width: 100%;
    height: 750px;
    padding-top: 10%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: none;
}

.isc2_recent_posts_title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    text-align: justify;
}

.isc2_recent_posts_list_container {
    width: 100%;
    height: 100%;
    padding-top: 5%;
    padding-left: 1.5rem;
}

.isc2_recent_posts_list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.isc2_recent_posts_list_item {
    font-size: 16px;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-align: left;
    color: rgb(96, 96, 96);
    grid-template-columns: repeat(2, 1fr);
    display: grid;
}

.isc2_recent_posts_list_item_img_container {
    width: 50%;
}

.isc2_recent_posts_list_item_img {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    object-fit: cover;
}

.isc2_recent_posts_list_item_text_container {
    width: 120%;
    margin-left: -4rem;
}

.isc2_recent_posts_list_item_text {
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    font-size: 0.9rem;
    font-weight: 700;
}

.isc2_recent_posts_list_item_text_selected {
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    font-size: 0.9rem;
    font-weight: 700;
    color: #ff5325;
}

.isc2_recent_posts_list_item:hover {
    transition: 0.3s ease-in-out;
    color: #ff5325;
}

.is_carousel {
    display: block;
    width: 90%;
    height: 100%;
    padding-top: 10%;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
}

.is_responsive_posts_container {
    width: 100%;
    height: auto;
    padding-top: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.is_responsive_posts_list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.is_responsive_posts_list_item {
    font-size: 16px;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-align: left;
    color: rgb(96, 96, 96);
    grid-template-columns: repeat(2, 1fr);
    display: grid;
}

.is_responsive_posts_list_item_img_container {
    width: 50%;
}

.is_responsive_posts_list_item_img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}

.is_responsive_posts_list_item_text_container {
    width: 120%;
    margin-left: -4rem;
}

.is_responsive_posts_list_item_text {
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    font-size: 0.9rem;
    font-weight: 700;
    margin-top: 1rem;
}

.is_responsive_posts_list_item_text_selected {
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    font-size: 0.9rem;
    font-weight: 700;
    color: #ff5325;
    margin-top: 1rem;
}

.is_responsive_posts_title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 1rem;
    text-align: justify;
    color: #004c90;
}

.image_credits {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    text-align: right;
    color: rgb(132, 132, 132);
}

.image_credits a {
    text-decoration: underline;
    color: rgb(132, 132, 132);
}
