.contact_container_1 {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
}

.cc1_title {
    font-size: 48px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.cc1_text {
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}

.contact_container_2 {
    width: 100%;
    padding-top: 20vh;
    padding-bottom: 20vh;
}

.contact_card_container {
    width: 100%;
    margin: auto;
}

.contact_contact_card {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 320px;
    height: 350px;
    margin: auto;
    position: relative;
    z-index: 2;
    margin-bottom: 4rem;
}

.contact_card_2 .contact_contact_card {
    padding: 2rem 1rem;
}

.contact_contact_card::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    z-index: 1;
    transition: all 0.3s ease-in;
    opacity: 0;
}

.contact_contact_card:hover {
    transition: all 0.3s ease-in;
    color: #fff;
    margin-top: -10px;
}

.contact_contact_card:hover::before {
    opacity: 1;
    z-index: -1;
}

.contact_card_icon_container {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.contact_card_icon {
    width: 50px;
    height: 50px;
    margin: auto;
}

.contact_card_text_container {
    width: 100%;
    text-align: center;
}

.contact_card_text_1 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 15px;
}

.contact_card_text_2 {
    font-size: 1rem;
    color: rgb(132, 132, 132);
    line-height: 1.5;    
}

.contact_card_text_3 {
    font-size: 1rem;
    color: rgb(132, 132, 132);
    margin-top: 5px;
}

.contact_contact_card:hover .contact_card_text_2 {
    color: #fff;
}

.contact_contact_card:hover .contact_card_text_3 {
    color: #fff;
}

.contact_container_3 {
    width: 100%;
    padding-bottom: 20vh;
}

.cc3_title {
    font-weight: 700;
    font-size: 2.2rem;
}

.cc3_content_container {
    width: 80%;
    margin: auto;
    margin-top: 5rem;
}

.contact_form_container {
    width: 100%;
}

.contact_form {
    width: 100%;
}

.contact_form_input, .contact_form_message {
    width: 100%;
    margin-bottom: 20px;
    padding: 1rem;
    border: 0px;
    border-radius: 10px;
    background-color: rgb(250, 250, 250);
}

.contact_form_message {
    height: 100px;
    resize: none;
}

.contact_form_submit_container {
    width: 100%;
    padding: 1rem;
    border: 0px;
    border-radius: 10px;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.contact_form_submit {
    text-decoration: none;
    color: #fff;
    border: none;
    background-color: transparent;
    font-size: 16px;
}

.contact_form_submit_container::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to left, #00d581, #00d581);
    z-index: 1;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    border-radius: 10px;
}

.contact_form_submit_container:hover::before {
    opacity: 1;
    z-index: -1;
}

.contact_details_container {
    width: 100%;
    grid-template-rows: 3;
    display: grid;
    margin-top: 4rem;
}

.contact_details_1_container,
.contact_details_2_container,
.contact_details_3_container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    margin-bottom: 40px;
}

.contact_details_icon_container {
    width: 20%;
}

.contact_details_icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    padding: 10px;
    color: #fff;
}

.contact_details_text_container {
    width: 100%;
    margin-left: -60%;
}

.contact_details_text_title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: left;
}

.contact_details_text {
    font-size: 1rem;
    color: rgb(132, 132, 132);
    margin-bottom: 5px;
    text-align: left;
}

.contact_details_2_container .contact_details_text_container {
    margin-left: -2.5%;
}

.contact_details_social_icon_container {
    width: 60%;
    margin-top: 20px;
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    grid-gap: 1rem;
    margin-top: 20px;
}

.contact_details_social_icon {
    width: 20px;
    height: 20px;
    color: #0094f8;
    cursor: pointer;
}

.contact_container_4 {
    width: 100%;
}

.contact_map_1 {
    height: 600px;
    display: none;
}

.contact_map_2 {
    height: 600px;
}

.contact_details_social_link {
    text-decoration: none;
    color: #0094f8;
    width: 20px;
    height: 20px;
}
