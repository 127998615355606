.navbar {
    height: 12vh;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: all .7s ease-in;
    z-index: 99;
}

.navbar_scrolled {
    position: fixed;
    transition: all .7s ease-in;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 99;
}

.navbar_logo {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 5vw;
    margin-right: 5vw;
    font-weight: 900;
}

.navbar_logo_img {
    width: 6vh;
    height: 6vh;
    margin-right: 1rem;
}

.navbar_logo_text {
    display: block;
}

.navbar_logo_text_1 {
    font-size: 4.5vw;
    font-weight: 900;
    text-align: left;
    color: #004c90;
}

.navbar_logo_text_2 {
    font-size: 3vw;
    font-weight: 900;
    text-align: left;
    color: #00a563;
}

.nav_items {
    display: flex;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: flex-end;
    margin-right: 2rem;
}

.nav_item {
    display: none;
    align-items: center;
    height: 8vh;
}

.nav_item_a {
    color: #004c90;
    text-decoration: none;
    margin-right: 1rem;
    padding: 30px 20px 30px 20px;
    font-size: 0.85vw;
    font-weight: 600;
    transition: all 0.35s ease-in;
    cursor: pointer;
}

.nav_item_a_scrolled {
    font-size: 0.65vw;
    transition: all 0.35s ease-in;
}

.nav_item a:hover {
    background-color: #fff;
    color: #ff5325;
    border-radius: 5px;
}

.demo_button_container {
    margin-right: 5vw;
    margin-left: 5vw;
    display: none;
}

.demo_button {
    border: none;
    background: transparent;
    padding: 12px 20px;
    font-size: 0.85vw;
    color: #fff;
    background-image: linear-gradient(to right, #0094f8, #00d581);
    border-radius: 5px;
    transition: all 0.3s ease-in;
    font-weight: 200;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.demo_button::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to left, #00d581, #00d581);
    z-index: 1;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    border-radius: 5px;
}

.demo_button:hover::before {
    opacity: 1;
    z-index: -1;
}

.services_menu, .platform_menu, .news_menu, .about_menu {
    width: 12vw;
    position: absolute;
    list-style: none;
    text-align: start;
    top: 10vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 3;
    animation: growDown 400ms ease-in-out forwards;
    transform-origin: top center;
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    100% {
        transform: scaleY(1)
    }
}

.services_menu.clicked, .platform_menu.clicked, .news_menu.clicked, .about_menu.clicked {
    display: none;
}

.services_menu li, .platform_menu li, .news_menu li, .about_menu li {
    background-color: #fff;
    cursor: pointer;
    /* padding: 0.5vw; */
    font-size: 0.85vw;
}

.services_list_item, .platform_list_item, .news_list_item, .about_list_item {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #000;
    padding: 1.5vw;
}

.services_list_item:hover, .platform_list_item:hover, .news_list_item:hover, .about_list_item:hover {
    background-color: #fff;
    border-radius: 5px;
}

.hamburger_menu {
    display: block;
    margin-right: 5vw;
    cursor: pointer;
    z-index: 6;
    position: absolute;
    left: 85%;
}

.hamburger_menu_line {
    width: 30px;
    height: 3px;
    background-color: #000;
    margin: 5px;
    transition: all 0.3s ease-in;
}

.hamburger_menu_list {
    position: absolute;
    top: 12vh;
    left: 50%;
    transform: translate(-50%);
    list-style: none;
    text-align: center;
    width: 100%;
    height: auto;
    background-color: rgb(226, 226, 226);
    z-index: 5;
    /* transition: all 0.3s ease-in; */
}

.hamburger_menu_list_scrolled {
    position: fixed;
    top: 12vh;
    left: 50%;
    transform: translate(-50%);
    list-style: none;
    text-align: center;
    width: 100%;
    height: auto;
    background-color: rgb(226, 226, 226, 1);
    z-index: 5;
    /* transition: all 0.3s ease-in; */
}

.hamburger_menu_list_item {
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 60px;
}

.hamburger_menu_list_item_drop {
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 30px;
}

.hamburger_nav_item_a {
    color: #004c90;
    text-decoration: none;
    margin-right: 1rem;
    padding: 30px 20px 30px 20px;
    font-size: 1.8vh;
    font-weight: 600;
    transition: all 0.35s ease-in;
}

.hamburger_menu_list_line {
    width: 0%;
    height: 0px;
    background-color: rgb(226, 226, 226, 0);
    /* margin: 5px; */
    margin-top: 20px;
    transition: all 0.3s ease-in;
    display: hidden;
}

.hamburger_menu_list_item_icon {
    width: 5vw;
    height: 5vw;
    color: #fff;
}
