.news_container_1 {
    padding-top: 20vh;
    padding-bottom: 20vh;
    background-image: linear-gradient(to bottom right, #0094f8, #00d581);
    color: #fff;
}

.nc1_title {
    font-size: 48px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.nc1_text {
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}

.news_container_2 {
    padding-top: 20vh;
    padding-bottom: 20vh;
}

.news_card_container {
    width: 100%;
    margin: auto;
    margin-bottom: 5rem;
}

.news_card {
    width: 90%;
    height: 100%;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.news_card_img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}

.news_icon {
    margin-right: 1rem;
}

.news_card_title {
    font-size: 1.2rem;
    color: #000;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: 1rem;
    padding-right: 1rem;
    height: 75px;
}

.news_card_title:hover {
    color: #ff5325;
    cursor: pointer;
}

.news_card_desc {
    font-size: 0.9rem;
    color: rgb(96, 96, 96);
    text-align: justify;
    margin: 0;
    padding: 0;
    margin-top: 2rem;
    margin-left: 1rem;
    padding-right: 1rem;
    height: 75px;
}

.news_card_date {
    font-size: 1rem;
    color: rgb(132, 132, 132);
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: 1rem;
}

.news_learn_button {
    background-image: linear-gradient(to right, #0094f8, #00d581);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.938rem 30px;
    font-size: 1rem;
    margin-top: 2rem;
    cursor: pointer;
    transition: all 0.2s ease-in;
    position: relative;
    z-index: 2;
}

.news_learn_button::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to left, #00d581, #00d581);
    z-index: 1;
    transition: opacity 0.3s ease-in;
    opacity: 0;
    border-radius: 5px;
}

.news_learn_button:hover::before {
    opacity: 1;
    z-index: -1;
}
